import authHeader from './auth-header';
import axios from "axios";
import { API_ADMIN_URL } from "./config";

// API to get admin by id
const readAdmin = (id) => {
    return axios.get(API_ADMIN_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all admins
const readAdmins = () => {
    return axios.get(API_ADMIN_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create admin
const createAdmin = (admin) => {
    return axios
        .post(API_ADMIN_URL, admin, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update admin
const updateAdmin = (id, admin) => {
    return axios
        .put(API_ADMIN_URL + id, admin, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete admin
const deleteAdmin = (id) => {
    return axios
        .delete(API_ADMIN_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable admin
const disableAdmin = (id) => {
    return axios
        .post(API_ADMIN_URL + id + '/disable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable admin
const enableAdmin = (id) => {
    return axios
        .post(API_ADMIN_URL + id + '/enable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to reset admin
const resetAdmin = (id) => {
    return axios
        .post(API_ADMIN_URL + id + '/reset', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const admin = {
    createAdmin,
    readAdmin,
    readAdmins,
    updateAdmin,
    deleteAdmin,
    enableAdmin,
    disableAdmin,
    resetAdmin
};

export default admin;