import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    FIRST_UPDATE_PASSWORD_SUCCESS,
    FIRST_UPDATE_PASSWORD_FAIL,
    PROFILE_UPDATE_NAME_SUCCESS,
    PROFILE_UPDATE_NAME_FAIL,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAIL,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
        case FIRST_UPDATE_PASSWORD_SUCCESS:
        case PROFILE_UPDATE_NAME_SUCCESS:
        case CURRENT_USER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case FIRST_UPDATE_PASSWORD_FAIL:
        case PROFILE_UPDATE_NAME_FAIL:
            // Nothing changed
            // If due to invalid user issue, refresh should kick the user out
            return state;
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
        case CURRENT_USER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}
export default authReducer;